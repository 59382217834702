'use strict';

var directiveModule = angular.module('angularjs-dropdown-multiselect', ['ngAria']);

directiveModule.directive('ngDropdownMultiselect', [
	'$filter',
	'$document',
	'$compile',
	'$parse',
	'$window',
	function($filter, $document, $compile, $parse, $window) {
		return {
			restrict: 'AE',
			scope: {
				selectedModel: '=',
				options: '=',
				extraSettings: '=',
				events: '=',
				searchFilter: '=?',
				translationTexts: '=',
				disabled: '=',
				alignment: '=?',
				nestedMultiselect: '=',
				parentOption: '=',
			},
			template: function(element, attrs) {
				var nestedMultiselect = attrs.nestedMultiselect ? true : false;

				var template =
					'<div class="multiselect-parent dropdown inline dropdown-multiselect" ng-class="{\'multiselect-open\' : open}">';
				if (!nestedMultiselect) {
					template +=
						'<button ng-disabled="disabled" type="button" class="dropdown-toggle" ng-class="settings.buttonClasses" ng-click="toggleDropdown()"><span class="button-text ellipsis">{{getButtonText()}}</span><span class="icon icon-ChevronDown" ng-class="settings.openIconClass"></span></button>';
				}

				if (attrs.alignment == 'left') {
					template +=
						'<ul class="dropdown-menu dropdown-menu-shadow dropdown-menu-form pull-left" id="nestedMultiselect{{getPropertyForObject(parentOption,settings.idProp)}}" ng-style="{display: open ? \'block\' : \'none\', height : settings.scrollable ? settings.scrollableHeight : \'auto\' }" >';
				} else {
					template +=
						'<ul class="dropdown-menu dropdown-menu-shadow dropdown-menu-form pull-right" id="nestedMultiselect{{getPropertyForObject(parentOption,settings.idProp)}}" ng-style="{display: open ? \'block\' : \'none\', height : settings.scrollable ? settings.scrollableHeight : \'auto\' }" >';
				}

				// check\uncheck all
				template +=
					'<li ng-if="settings.showCheckAll && !settings.showUncheckAll && showCheckAll()" class="action-btn"><a data-ng-click="selectAll()">{{texts.checkAll}}</a></li>';
				template +=
					'<li ng-if="settings.showUncheckAll && !settings.showCheckAll && showUncheckAll()" class="action-btn"><a data-ng-click="deselectAll();">{{texts.uncheckAll}}</a></li>';
				template +=
					'<li ng-if="settings.showUncheckAll && settings.showCheckAll" class="action-btn"><a data-ng-click="toggleAll();">{{texts.toggleAll}}</a></li>';

				template +=
					'<li ng-hide="(settings.selectionLimit > 0) && !settings.showUncheckAll" class="divider action-btn"></li>';

				// search
				template +=
					'<li ng-show="settings.enableSearch"><div class="dropdown-header"><input type="text" class="form-control" style="width: 100%;" ng-model="searchFilter" placeholder="{{texts.searchPlaceholder}}" /></li>';
				template += '<li ng-show="settings.enableSearch" class="divider"></li>';

				template +=
					'<li role="presentation" ng-init="initOption(option)" ng-mouseover="setElementHover(option, true)" ng-mouseleave="setElementHover(option,false)" ng-repeat="option in options | filter: searchFilter">';

				template +=
					'<div ng-if="option.childOptions" ng-dropdown-multiselect nested-multiselect="true" parent-option="option" disabled="disabled" selected-model="selectedModel" options="option.childOptions" extra-settings="extraSettings.childSettings"></div>';

				template +=
					'<a ng-if="option.categoryLabel" class="multiselect-category-label" ng-class="::{\'multiselect-category-label-divider\' : labelRequiresDivider(option)}"> {{option.categoryLabel}} </a>';
				template +=
					'<a ng-if="!option.categoryLabel" tabindex="0" id="{{getPropertyForObject(option,settings.idProp)}} "role="menuitem" ng-class="{\'dropdown-multiselect-parent-option\': option.childOptions}" ng-click="setSelectedItem(getPropertyForObject(option,settings.idProp))">';

				template +=
					"<span ng-if=\"!option.categoryLabel\" data-ng-class=\"{'icon icon-multiselect-checked icon-CheckboxCompositeReversed': option.checked === 'checked', 'icon icon-multiselect-notchecked icon-Checkbox': option.checked === 'notChecked', 'icon icon-multiselect-indeterminate icon icon-CheckboxFill ': option.checked === 'partlyChecked'}\"/>";

				template +=
					'<i ng-if="!option.categoryLabel" class="icon multiselect-icon" ng-class="getPropertyForObject(option, settings.iconProp)" /> {{getPropertyForObject(option, settings.displayProp)}}';

				template += '</a>';
				template += '</li>';

				template += '<li class="divider" ng-show="settings.selectionLimit > 1"></li>';
				template +=
					'<li role="presentation" ng-show="settings.selectionLimit > 1"><a role="menuitem">{{selectedModel.length}} {{texts.selectionOf}} {{settings.selectionLimit}} {{texts.selectionCount}}</a></li>';

				template += '</ul>';
				template += '</div>';

				element.html(template);
			},
			link: function($scope, $element, $attrs) {
				var $dropdownTrigger = $element.children()[0];
				$scope.open = false;

				$scope.toggleDropdown = function() {
					$scope.open = !$scope.open;
					if (!$scope.open) {
						$scope.externalEvents.onSelectionClosed();
					}
				};

				$scope.externalEvents = {
					onItemSelect: angular.noop,
					onItemDeselect: angular.noop,
					onSelectAll: angular.noop,
					onDeselectAll: angular.noop,
					onInitDone: angular.noop,
					onMaxSelectionReached: angular.noop,
					onSelectionClosed: angular.noop,
				};

				$scope.settings = {
					dynamicTitle: true,
					scrollable: false,
					scrollableHeight: '300px',
					closeOnBlur: true,
					displayProp: 'label',
					iconProp: 'icon',
					idProp: 'id',
					externalIdProp: 'id',
					enableSearch: false,
					selectionLimit: 0,
					showCheckAll: true,
					showUncheckAll: true,
					closeOnSelect: false,
					buttonClasses: 'btn btn-default',
					openIconClass: 'icon-sm',
					closeOnDeselect: false,
					smartButtonMaxItems: 0,
					smartButtonTextConverter: angular.noop,
					allSelectedText: '',
				};

				$scope.texts = {
					checkAll: 'Check All',
					uncheckAll: 'Uncheck All',
					toggleAll: 'Check\\Uncheck All',
					selectionCount: 'checked',
					selectionOf: '/',
					searchPlaceholder: 'Search...',
					buttonDefaultText: 'Select',
					dynamicButtonTextSuffix: 'checked',
				};

				$scope.getPropertyForObject = function(object, property) {
					if (angular.isDefined(object) && object.hasOwnProperty(property)) {
						return object[property];
					}

					return '';
				};

				$scope.searchFilter = $scope.searchFilter || '';

				if (angular.isDefined($scope.parentOption)) {
					var objectId = $scope.getPropertyForObject(
						$scope.parentOption,
						$scope.settings.idProp
					);
					$scope.$watch('parentOption.elementHover', function(newValue) {
						if ($scope.open !== $scope.parentOption.elementHover) {
							placeNestedMultiselect(objectId);
							$scope.toggleDropdown();
						}
					});

					angular.element($window).bind('resize', placeNestedMultiselect(objectId));
					placeNestedMultiselect(objectId);
					$scope.$on('$destroy', function() {
						angular.element($window).off('resize', placeNestedMultiselect(objectId));
					});
				}

				$scope.$watch('selectedModel', function(newValue) {
					angular.forEach($scope.options, function(option) {
						option.checked = $scope.optionCheckStatus(option);
						if ($scope.nestedMultiselect) {
							$scope.parentOption.checked = $scope.optionCheckStatus(
								$scope.parentOption
							);
						}
					});
				});

				function placeNestedMultiselect(id) {
					var elem = $('#' + id);
					if (!elem || !elem.offset()) {
						return false;
					} else {
						var totalScreen = $(window).width();

						// Check if we have enough room for a submenu on the right of the parent multiselect
						var availableSpace = totalScreen - (elem.outerWidth() + elem.offset().left);

						var hasSpace = availableSpace > 240; // 240 - enough for a relatively long string
						var childElem = $('#nestedMultiselect' + id);
						childElem.css('margin-top', 0);
						if (hasSpace) {
							childElem.css('left', elem.outerWidth());
							childElem.css('right', 'auto');
							return true;
						} else {
							childElem.css('left', 'auto');
							childElem.css('right', 0);
							return false;
						}
					}
				}

				angular.extend($scope.settings, $scope.extraSettings || []);
				angular.extend($scope.externalEvents, $scope.events || []);
				angular.extend($scope.texts, $scope.translationTexts);

				function getFindObj(id) {
					var findObj = {};
					findObj[$scope.settings.idProp] = id;
					return findObj;
				}

				function getOptionsLength(optionsArray) {
					var length = 0;
					for (var i = 0; i < optionsArray.length; i++) {
						if (angular.isDefined(optionsArray[i].childOptions)) {
							length += getOptionsLength(optionsArray[i].childOptions);
						} else if (!angular.isDefined(optionsArray[i].categoryLabel)) {
							length++;
						}
					}

					return length;
				}

				function clearObject(object) {
					for (var prop in object) {
						delete object[prop];
					}
				}

				$scope.initOption = function(option) {
					option.elementHover = false;
					option.checked = $scope.optionCheckStatus(option);
					if ($scope.nestedMultiselect) {
						$scope.parentOption.checked = $scope.optionCheckStatus($scope.parentOption);
					}
					if ($scope.settings.externalIdProp !== '') {
						option[$scope.settings.externalIdProp] = option[$scope.settings.idProp];
					}
				};

				$scope.setElementHover = function(option, hover) {
					if (angular.isDefined(option.childOptions)) {
						option.elementHover = hover;
					}
				};

				$scope.labelRequiresDivider = function(option) {
					if (angular.isDefined(option.categoryLabel) && $scope.options[0] !== option) {
						return true;
					}
					return false;
				};

				if ($scope.settings.closeOnBlur && !angular.isDefined($scope.nestedMultiselect)) {
					$('body, .dropdown-toggle').on('click', function(e) {
						var target = e.target.parentElement;
						var parentFound = false;

						while (angular.isDefined(target) && target !== null && !parentFound) {
							if (
								_.includes(target.className.split(' '), 'multiselect-parent') &&
								!parentFound
							) {
								if (target === $dropdownTrigger) {
									parentFound = true;
								}
							} else if (_.includes(target.className.split(' '), 'action-btn')) {
								parentFound = true;
							}

							target = target.parentElement;
						}

						if (!parentFound) {
							$scope.$apply(function() {
								var wasOpened = $scope.open;
								$scope.open = false;
								if (wasOpened) {
									$scope.externalEvents.onSelectionClosed();
								}
							});
						}
					});
				}

				function addOptionDisplayText(optionItem, itemsText) {
					if ($scope.optionCheckStatus(optionItem) === 'checked') {
						var displayText = $scope.getPropertyForObject(
							optionItem,
							$scope.settings.displayProp
						);
						var converterResponse = $scope.settings.smartButtonTextConverter(
							displayText,
							optionItem
						);

						itemsText.push(converterResponse ? converterResponse : displayText);
					}
				}

				$scope.getButtonText = function() {
					if (
						$scope.settings.dynamicTitle &&
						($scope.selectedModel.length > 0 ||
							(angular.isObject($scope.selectedModel) &&
								_.keys($scope.selectedModel).length > 0))
					) {
						if (
							$scope.selectedModel.length === getOptionsLength($scope.options) &&
							$scope.settings.allSelectedText
						) {
							return $scope.settings.allSelectedText;
						}
						if ($scope.settings.smartButtonMaxItems > 0) {
							var itemsText = [];

							angular.forEach($scope.options, function(optionItem) {
								if (angular.isDefined(optionItem.childOptions)) {
									angular.forEach(optionItem.childOptions, function(childOption) {
										addOptionDisplayText(childOption, itemsText);
									});
								} else {
									addOptionDisplayText(optionItem, itemsText);
								}
							});

							if ($scope.selectedModel.length > $scope.settings.smartButtonMaxItems) {
								itemsText = itemsText.slice(0, $scope.settings.smartButtonMaxItems);
								itemsText.push('...');
							}

							return itemsText.join(', ');
						} else {
							var totalSelected = angular.isDefined($scope.selectedModel)
								? $scope.selectedModel.length
								: 0;

							if (totalSelected === 0) {
								return $scope.texts.buttonDefaultText;
							} else {
								return totalSelected + ' ' + $scope.texts.dynamicButtonTextSuffix;
							}
						}
					} else {
						return $scope.texts.buttonDefaultText;
					}
				};

				$scope.selectAll = function() {
					$scope.deselectAll(false);
					$scope.externalEvents.onSelectAll();

					angular.forEach($scope.options, function(option) {
						if (!angular.isDefined(option.categoryLabel)) {
							$scope.setSelectedItem(option[$scope.settings.idProp], true);
						}
					});
				};

				$scope.deselectAll = function(sendEvent) {
					sendEvent = sendEvent || true;

					if (sendEvent) {
						$scope.externalEvents.onDeselectAll();
					}

					angular.forEach($scope.options, function(option) {
						if (angular.isDefined(option.childOptions)) {
							angular.forEach(option.childOptions, function(childOption) {
								if (!angular.isDefined(childOption.categoryLabel)) {
									childOption.checked = 'notChecked';
									$scope.checkChange(childOption);
								}
							});
						}
						option.checked = 'notChecked';
						$scope.checkChange(option);
					});
				};

				$scope.toggleAll = function() {
					if ($scope.showCheckAll()) {
						$scope.selectAll();
					} else {
						$scope.deselectAll();
					}
				};

				$scope.setSelectedItem = function(id, dontRemove) {
					var findObj = getFindObj(id);
					var option = _.find($scope.options, findObj);

					if (angular.isDefined(option.categoryLabel)) {
						return;
					}

					dontRemove = dontRemove || false;

					var newCheckStatus = '';

					if ($scope.optionCheckStatus(option) === 'checked') {
						if (dontRemove) {
							return;
						}
						newCheckStatus = 'notChecked';
					} else {
						newCheckStatus = 'checked';
					}

					if (angular.isDefined(option.childOptions)) {
						for (var i = 0; i < option.childOptions.length; i++) {
							if (!angular.isDefined(option.childOptions[i].categoryLabel)) {
								option.childOptions[i].checked = newCheckStatus;
								$scope.checkChange(option.childOptions[i]);
							}
						}

						option.checked = $scope.optionCheckStatus(option);
						return;
					}

					option.checked = newCheckStatus;
					$scope.checkChange(option);

					if ($scope.settings.closeOnSelect) $scope.open = false;
				};

				$scope.checkChange = function(option) {
					if (
						angular.isDefined(option.childOptions) ||
						angular.isDefined(option.categoryLabel)
					) {
						return;
					}

					if (option.checked === $scope.optionCheckStatus(option)) {
						return;
					}

					if (option.checked === 'checked') {
						$scope.selectedModel.push(option);
						$scope.externalEvents.onItemSelect(option);
					} else if (option.checked === 'notChecked') {
						var id = $scope.getPropertyForObject(option, $scope.settings.idProp);
						$scope.selectedModel.splice(
							_.findIndex($scope.selectedModel, getFindObj(id)),
							1
						);
						$scope.externalEvents.onItemDeselect(option);
					}

					if ($scope.nestedMultiselect) {
						$scope.parentOption.checked = $scope.optionCheckStatus($scope.parentOption);
					}
				};

				$scope.optionCheckStatus = function(option) {
					if (angular.isDefined(option.categoryLabel)) {
						return '';
					}
					if (angular.isDefined(option.childOptions)) {
						var checkedChildren = 0;
						for (var i = 0; i < option.childOptions.length; i++) {
							if (option.childOptions[i].checked === 'checked') {
								checkedChildren++;
							}
						}
						if (checkedChildren === getOptionsLength(option.childOptions)) {
							return 'checked';
						}
						if (checkedChildren === 0) {
							return 'notChecked';
						}

						return 'partlyChecked';
					}

					if (
						angular.isObject($scope.selectedModel) &&
						_.keys($scope.selectedModel).length > 0
					) {
						if (angular.isDefined(option.childOptions)) {
							return '';
						}

						var id = $scope.getPropertyForObject(option, $scope.settings.idProp);
						var exists = _.findIndex($scope.selectedModel, getFindObj(id)) !== -1;
						if (exists) {
							return 'checked';
						} else {
							return 'notChecked';
						}
					} else {
						return 'notChecked';
					}
				};

				$scope.showCheckAll = function() {
					if (!$scope.settings.showCheckAll) return false;

					var show = false;
					angular.forEach($scope.options, function(value) {
						if ($scope.optionCheckStatus(value) !== 'checked') {
							show = true;
						}
					});

					return show;
				};

				$scope.showUncheckAll = function() {
					if (!$scope.settings.showUncheckAll) return false;

					var show = false;
					angular.forEach($scope.options, function(value) {
						if (
							$scope.optionCheckStatus(value) === 'checked' ||
							$scope.optionCheckStatus(value) === 'partlyChecked'
						) {
							show = true;
						}
					});

					return show;
				};

				$scope.externalEvents.onInitDone();
			},
		};
	},
]);
